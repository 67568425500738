.home {

  // border: 1px solid red;
  // overflow-x: hidden;
  // overflow-y: scroll;

  .blob_01 {

    position: absolute;
    top: 250px;
    left: -360px;
    height: 500px;
    opacity: 0.7;
    transform: rotate(90deg);

    z-index: -99;

    @include medium {
      top: 100px;
      left: -300px;
      height: 800px;      
    }

    @include large { 
      top: 100px;
      left: -400px;
      height: 1000px;      
    }

  }

  .blob_02 {

    position: absolute;
    top: 520px;
    right: -100px;
    height: 400px;
    opacity: 0.7;
    transform: rotate(320deg);

    z-index: -99;

    @include medium {
      top: 420px;
      right: 25%;
      height: 800px;
    }

    @include large {
      top: 420px;
      right: 15%;
      height: 800px;
    }

  }

  .blob_03 {

    position: absolute;
    top: -100px;
    right: -100px;
    height: 400px;
    opacity: 0.7;

    z-index: -99;

    @include medium {
      top: -100px;
      right: 0;
      height: 600px;
    }

    @include large {
      top: -300px;
      right: 5%;
      height: 800px;
    }

  }
  
  .main {

    .content {

      // overflow: hidden;

      .landing {
        
        // border: 1px solid gold;
        padding: 0;

        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        @include medium {
          
          padding: 80px;

        }

        @include large {
          
          padding: 160px;

        }

        .intro {

          // border: 1px solid forestgreen;

          font-size: 3rem;
          font-weight: bold;
          line-height: 1em;
          letter-spacing: 0.2rem;
          text-transform: uppercase;
          margin: 0;
          text-align: right;

          margin-top: 2rem;

          @include medium {
            margin-top: 0;
            font-size: 6rem;
          }

          @include large {
            font-size: 7rem;
          }

          .ideas {
              -webkit-text-stroke: 1px white;
              color: #0000;

              @include medium {
                -webkit-text-stroke: 2px white;
              }
          }

          .into {
            font-size: 3rem;
            // margin-right: 1rem;

            @include medium {
              margin-right: 1rem;
            }
          }

          .gold {
            font-family: _font(family_titles);
            font-size: 10rem;
            font-weight: normal;
            text-transform: lowercase;
            line-height: 1em;
            margin-right: 0;
            letter-spacing: 0;

            @include medium {
              font-size: 16rem;
              line-height: 0.6em;
              margin-right: -100px;
            }


            @include large {
              font-size: 18rem;
              line-height: 2rem;
              margin-right: -160px;
            }
          }

        }

        .paragraph {

          // border: 1px solid coral;

          width: 100%;
          margin-top: 4rem;
          margin-bottom: 2rem;

          font-size: 1rem;
          line-height: 2em;
          letter-spacing: 0.1em;

          @include medium {
            width: 50%;
            margin-top: 0;
          }

          @include large {
            width: 30%;
            margin-top: -4rem;
          }

          &.solutions {
            align-self: flex-end;
            margin-top: 0;
            text-align: right;

            @include large {
              text-align: left;
            }
          }

        }

      }

      .clients {

        // border: 1px solid cyan;
        margin: 200px 0;
        // overflow-x: hidden;
        // overflow-y: visible;

        .title {

          font-family: _font(family_body) !important;
          font-size: 3rem;
          font-weight: bold;
          // letter-spacing: 0.2em;
          margin: 0;
          padding: 0;
          margin-bottom: 2rem;

          @include medium {
            margin-bottom: 0;
          }

        }

        .slider {

          width: calc(100% + 20rem);
          height: 80px;
          margin-left: -10rem;
          margin-bottom: 2rem;
          overflow: hidden;

          transform: rotate(-10deg);

          @include medium {
            height: 110px;
          }

          &.first {
            background-image: linear-gradient(91deg, #E3306B, #FF7340);
          }

          &.second {
            background-image: linear-gradient(93deg, #D756E5, #EF5F5F);

            .slider_list {

              transition: none;
              transition-timing-function: linear !important;
              transform: translate(-10%, 0);

              &.animation {
                transition: all 1000ms linear;
                transition-timing-function: linear !important;
                transform: translate(0, 0);
              }

            }
          }

          .slider_list {

            // width: auto;
            height: 100%;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            // margin-top: 60px;

            // border: 1px solid cyan;

            transition-timing-function: linear !important;
            transition: none;

            &.animation {
              transition: all 1000ms linear;
              transition-timing-function: linear !important;
              transform: translate(-10%, 0);
            }

            .slider_wrapper {

              // min-width: 20% !important;
              min-width: 10%;
              height: 100%;
              // margin: 0 15px;

              display: flex;
              justify-content: center;
              align-items: center;

              // border: 1px solid cyan;

              @include medium {
                min-width: 10%;
                height: calc(100% - 20px);
              }

              .slider_image {

                max-width: 100%;
                max-height: 100%;

                opacity: 0.6;
                margin: 0;
                padding: 0;
                
              }
            }

          }

        }

      }

      .work {

        // border: 1px solid coral;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        .together, .contact {

          font-size: 2.5rem;
          font-weight: bold;
          line-height: 1em;
          margin: 1rem;
          text-align: center;

        }

        .contact {
          font-size: 1.5rem;
          margin-bottom: 8rem;
        }

        .map {

          height: 300px;
          width: 100%;
          border: 1px solid #333;

          font-family: _font(family_body) !important;
          // background-image: linear-gradient(120deg, steelblue, lightskyblue);

          @include medium {
            height: 500px;
          }

          @include large {
            height: 700px;

          }

          .google_maps {
            // border: 1px solid gold;
          }

          .map-container {
            // border: 1px solid gold;
            width: 100%;
            height: 100%;
          }

          .map_frame {
            width: 100%;
            height: 100%;
            border: none;
          }
        }
        
      }

    }

  }

}