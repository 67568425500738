.video {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(black, 0.85);

  z-index: 1000000;

  &.hidden {
    display: none;
  	background: none;
  	z-index: -1000;
  }

  .video_container {

  	position: relative;
    width: calc(100% - 2rem);
    height: 500px;

  	padding: 0;

    @include medium {
      width: 640px;
      height: 360px;

    }

    @include large {
      width: 1280px;  
      height: 720px;
    }

    .video_frame {

      width: 100%;
      height: 100%;


      // border: 1px solid black;
      border-radius: 1rem;
      overflow: hidden;

    }

  	.video_close {

  		position: absolute;
  		top: -70px;
  		right: 0;

      width: 50px;
      height: 50px;

      // border: 1px solid cyan;

      @include medium {

        top: -60px;
        right: -60px;

        width: 50px;
        height: 50px;

      }

  		.video_icon {

        width: 100%;
        height: 100%;

  			color: white;
  			font-size: 2rem;
  			line-height: 1rem;
  			cursor: pointer;

        border: 1px solid blue

  			&:hover {
  				// color: _palette(accent_color);
  			}
  		}
  	}
  }

}