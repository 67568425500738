.contents {

	.blob_01 {

		position: absolute;
		top: 200px;
		left: -200px;
		height: 500px;
		opacity: 0.7;
		transform: rotate(180deg);

		z-index: -99;

	    @include medium {
			top: -200px;
			left: -20%;
			height: 700px;
	    }

	    @include large { 
			top: -200px;
			left: -10%;
			height: 1000px;
	    }

	}

	.blob_06 {

		position: absolute;
		top: -100px;
		right: -200px;
		height: 400px;
		opacity: 0.7;
		// transform: rotate(20deg);

		z-index: -99;

	    @include medium {
			top: -100px;
			right: 0;
			height: 500px;
	    }

	    @include large { 
			top: -100px;
			right: 10%;
			height: 600px;
	    }

	}

	.main {

		.banner {

			.title {

				text-align: center !important;
			}
		}

		.content {

			// border: 1px solid red;

			display: flex;
			flex-flow: column nowrap;
			align-items:center;
			// margin-bottom: 8rem !important;

			.paragraph {

				width: 100%;
				// font-size: 1.1rem;
				// line-height: 2em;
				// letter-spacing: 0.1em;
				text-align: center;

				@include medium {
					width: 80%;
				}

				@include large {
					// width: 100%;
				}

			}

			.lists {
				
				width: 100%;
				// border: 1px solid gold;
				padding: 0;
				margin: 0;
				margin-top: 2rem;

				display: flex;
				flex-flow: column nowrap;
				justify-content: space-evenly;
				align-items: flex-start;

				@include medium {
					flex-direction: row;
				}

				@include large {
					width: 70%;
				}

				.list {

					width: 90%;
					padding: 0;
					margin: 0 auto;

					@include medium {
						width: 30%;
					}

					// @include large {
					// 	width: 30%;
					// }

					.item {
						margin-bottom: 1.5rem;
						font-size: 1rem;
						letter-spacing: 0.1em;
					}
				}

			}

			.images {

				position: relative;

				width: calc(100% - 2rem);
				// border: 1px solid tomato;

				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				align-items: stretch;

				@include medium {
					flex-flow: row wrap;
				}

				.image_wrapper {

					width: 100%;
					margin: 0;
					padding: 1rem;
					display: flex;
					flex-flow: row nowrap;
					justify-content: center;
					align-items: flex-end;

					@include medium {
						width: 25%;
					}

					// border: 1px solid forestgreen;

					.bottom {

						// align-items: flex-end;

					}

					.top {

						align-self: flex-start;

					}

					&.double {
						flex-grow: 2;
					}

					.image {

						width: 100%;
						display: inline-block;
						// border: 1px solid purple;
						opacity: 0.6;

						border-radius: 2rem;

						&.small {
							width: 90%;
						}

						&.circle {
							border-radius: 50%;
						}
					}
				}

				.images_text {

					position: absolute;

					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
					display: none;

					@include medium {
						display: flex;
					}

					.images_center {

						position: relative;
						// border: 1px solid cyan;
						margin: 0;
						padding: 0;

						transform: scale(0.8);

						@include large {
							transform: scale(1);
						}

						* {
							margin: 0;
							padding: 0;
						}

						.meta, .verse {
							font-size: 9rem;
							font-weight: bold;
							line-height: 0.8em;
							// letter-spacing: 0.0.5em;
							// border: 1px solid red;
							-webkit-text-stroke: 1px white;
							color: #0000;
						}

						.verse {
							writing-mode: vertical-lr;
							transform: rotate(180deg);

						}

						.types_wrapper {

							position: absolute;
							top: 8rem;
							width: 100%;
							height: calc(100% - 8rem);

							display: flex;
							justify-content: flex-end;
							align-items: center;

							// border: 1px solid white;

							.types {
								font-size: 2.5rem;
								font-weight: bold;
								line-height: 1.5em;
								text-align: right;
							}

						}

					}

				}
			}
		}
	}
}