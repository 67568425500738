.filmmakers {

	// overflow: hidden;

	.blob {
		position: absolute;
		top: -150px;
		right: -150px;
		height: 500px;
		opacity: 0.7;
		transform: rotate(110deg);

		z-index: -99;

	    @include medium {
			top: -300px;
			right: 10%;
			height: 800px; 
	    }

	    @include large { 
			top: -300px;
			right: 20%;
			height: 1000px;
	    }

	}
	
	.main {

		.content {

			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start !important;
			align-items: flex-start !important;
			padding: 0;
			margin: 0;
			border: none !important;
			// border: 1px solid red !important;
			// margin-bottom: 4rem !important;

			.intro {

				font-size: 1.1rem;
				line-height: 2em;
				letter-spacing: 0.1em;
				text-align: center;
				width: 100%;
				margin: 0;
				// border: 1px solid red;

				@include small {
					// border: 1px solid gold;
				}

				@include medium {
					width: 100%;
					// border: 1px solid red;
				}

			}

			.subtitle {

				width: 100%;
				margin-top: 4rem;
				font-size: 1.6rem;
				line-height: 2em;
				letter-spacing: 0.1em;
				text-align: center;

				.link {
					color: deepskyblue;
					text-decoration: underline;
				}

			}

			.list {

				// border: 1px solid gold;
				width: 100%;
				margin: 2rem 0;
				padding: 0;

				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;
				align-items: flex-start;

				.item {

					// border: 1px solid tomato;
					width: 100%;
					max-width: 1200px;
					list-style: none;
					margin: 0;
					padding: 0;
					margin-top: 2rem;

					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					align-items: flex-start;

					@include small {
						// border: 1px solid magenta;
					}

					@include medium {
						// border: 1px solid gold;
						margin-top: 0;
					}

					@include large {
						// margin-top: -4rem;
						// border: 1px solid limegreen;
					}

					&:first-child {
						margin-top: 4rem;
					}

					&:nth-child(even) {

						align-self: flex-end;
						flex-flow: row-reverse nowrap;

						.section {

							&.left {

								.image {

									width: 100%;
									max-width: 400px;
									text-align: left;
									margin-right: auto;

								}

							}

							.name {

								margin-right: -4rem;
								text-align: right;

								@include medium {
								
									margin-right: -4rem;

								}

							}

							.paragraph {

								text-align: right;
								margin-right: 2rem;

								@include medium {
									text-align: right;
								}

							}

							.link {

								margin-right: 1rem;
								text-align: right;

								z-index: 1000;

								.link_text {
									margin-left: auto;
								}


							}

						}

					}

					@include medium {
						width: 80%;
						flex-flow: row nowrap;
					}

					.section {

						position: relative;
						width: 100%;
						// border: 1px solid red;

						&.left {

							// max-width: 600px;
							display: flex;
							justify-content: center;
							align-items: center;

							@include medium {
								max-width: 500px;
								justify-content: flex-end;
							}

							.image {

								width: 100%;
								max-width: 400px;
								// text-align: right;
								// border: 1px solid gold;

								// @include medium {
								// 	max-width: 500px;
								// }
							}

						}

						.name {
							
							font-size: 2.5rem !important;
							font-family: _font(family_body);

							// border: 1px solid red;
							margin: 0;
							// margin-top: -6rem;
							// margin-bottom: 4rem;
							margin-top: 1rem;
							margin-left: -4rem;
							text-align: left;

							// margin-top: -100%;
							@include small {
								margin-top: 3rem;
							}

							@include medium {
							
								margin-left: -4rem;
								margin-top: 6rem;
								margin-bottom: 2rem;
								text-align: left;

							}

						}

						.paragraph {

							font-size: 1rem;
							margin-left: 2rem;

							opacity: 0.8;
							text-align: left;

							@include medium {
								text-align: left;
							}

						}

						.link {

							cursor: default;
							display: flex;
							margin-right: 1rem;

							.link_text {

								font-size: 1.2rem;
								text-transform: uppercase;
								font-weight: bold;
								padding: 0.5rem 1rem;

								cursor: pointer;

								&:hover {

								}

							}
							
						}

					}

				}
			}

		}
	}

}