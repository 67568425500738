/* Typography */

@font-face {
  font-family: "Bonques";
  src: url("../fonts/bonques.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: url("../fonts/product_sans.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: url("../fonts/product_sans_italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Product Sans";
  src: url("../fonts/product_sans_bold.ttf");
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "Product Sans";
  src: url("../fonts/product_sans_bold_italic.ttf");
  font-weight: 600;
  font-style: italic;
}

* {
	font-family: _font(family_body);
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	
	font-family: _font(family_body);
	font-weight: _font(weight, regular);
	color: _palette(white);
	font-size: 12px;
	line-height: 1.5em;
	letter-spacing: 0.025em;

	@include small {
		font-size: 12px;
	}
	
	@include medium {
		font-size: 13px;
	}
	
	@include large {
		font-size: 14px;
	}
	
	@include xlarge {
		font-size: 15px;
	}

}

h1, h2, h3, h4, h5, h6 {
	font-family: _font(family_titles);
	font-weight: _font(weight, bold);
	line-height: 1em;
	color: inherit;

	a {
		color: _palette(white);
		text-decoration: none;
	}

}

p, ul, ol, dl, table {
	font-family: _font(family_body);
	font-weight: _font(weight, regular);
  color: _palette(white);
}

.paragraph {

	// font-size: 1rem;
	line-height: 1.2em;

	&.bold {
		font-weight: bold;
	}

	@include medium {

		// font-size: 1rem;
		line-height: 1.5em;

	}

	@include large {

		// font-size: 1rem;
		line-height: 2em;
		letter-spacing: 0.1em;

	}

}

strong, b {
	font-weight: _font(weight, bold);
	color: inherit;
}

em, i {
	font-style: italic;
}

a {
	color: _palette(white);
	text-decoration: none;
	@include transition(all, _duration(transition_hover), ease-in-out);
}

input, textarea, select {
	
	font-family: _font(family_body);
	font-weight: _font(weight, regular);
	color: _palette(black);
	font-size: 16px;
	line-height: 1.5em;
	letter-spacing: 0.025em;

}

button {
	font-family: _font(family_body);
	@include transition(all, _duration(transition_hover), ease-in-out);
}

sub {
	position: relative;
	top: 0.5em;
	font-size: 0.8em;
}

sup {
	position: relative;
	top: -0.5em;
	font-size: 0.8em;
}

hr {
	border: 0;
	border-top: solid 1px _palette(white);
}

blockquote {
	border-left: solid 0.5em _palette(white);
	padding: 1em 0 1em 2em;
	font-style: italic;
}
	
