.producers {

	.blob {
		position: absolute;
		top: -50px;
		right: -50px;
		height: 400px;
		opacity: 0.7;

		z-index: -99;

	    @include medium {
			top: -200px;
			right: -100px;
			height: 900px;
	    }

	    @include large { 
			top: -300px;
			right: 10%;
			height: 1100px;
	    }

	}
	
	.main {

		.content {

			// border: none !important;
			// border: 1px solid red !important;

			width: 100%;
			// margin-bottom: 4rem !important;

			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			align-items: center;

			@include large {
				flex-direction: row;
				align-items: flex-start;
			}

			.wrapper {

				width: 100%;
				margin: 0;

				@include large {
					width: 60%;
					margin-top: 1rem;
					margin-right: 4rem;
				}

				// .paragraph {

				// 	font-size: 1rem;
				// 	line-height: 1.5em;

				// 	@include medium {

				// 		font-size: 1.1rem;
				// 		line-height: 2em;

				// 	}

				// 	@include large {

				// 		font-size: 1.2rem;
				// 		line-height: 2em;
				// 		letter-spacing: 0.1em;

				// 	}

				// }

			}

			.image {

				width: calc(100% - 4rem);
				margin: 4rem;
				opacity: 0.6;
				// margin-top: 8rem;

				@include medium {

					width: 50%;
					margin: 0;
					margin-top: 4rem;

				}

				@include large {

					width: 30%;
					margin: 0;

				}

			}

		}

	}

}