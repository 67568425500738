
// Duration.
	$duration: (
		transition_hover:	0.15s,
		transition_sidebar:	0.3s,
	);

// Size.
	$size: (
		header_height:	4rem,
		footer_height:	3rem,

		sidebar: (
			xsmall: 220px,
			small: 	240px,
			medium: 260px,
			large: 	280px,
			xlarge: 300px,
		),

		breakpoint: (
			small: 	600px,
			medium: 900px,
			large: 	1200px,
			xlarge: 1930px,
		),
	);

// Font.
	$font: (

		family_body:		('Product Sans', sans-serif),
		family_titles:		('Bonques', serif),

		weight: (
			regular:		400,
			bold:			600,
		),

	);

// Palette.
	$palette: (

		white:				#ffffff,
		black:				#000000,

		grey: (
			light:		#DDDDDD,
			medium:		#A0A0A0,
			dark:		#3E3E3E,
		),

		font: (
			light:		#6C6C6C,
			dark:		#303030,
		),

	);



