.contact {

	.main {

		.banner {

			.title_wrapper {

				display: flex;

				.title {

					text-align: right;
					
				}

			}
		}
	}
}