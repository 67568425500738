.header {

	height: 70px;
	width: 100%;
	margin: 0 auto;
	// background: black;

	border-bottom: 1px solid white;

	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: flex-end;

	z-index: 99;

	&.open {

		position: fixed;
		width: calc(100% - 2rem);
		left: 1rem;
		top: 0;
		right: 1rem;

		@include medium {
			width: calc(100% - 4rem);
			left: 2rem;
			right: 2rem;
		}

	}

	@include medium {
		height: 80px;
	}

	@include large {
		height: 100px;
	}

	.content {

		height: calc(100% - 2rem);
		width: 100%;
		margin-bottom: 1rem;

		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-end;
		// padding: 0 1rem;

		// border: 1px solid gold;

		.sector {
			// border: 1px solid salmon;
			height: 100%;
		}


		.left {

			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: flex-end;

			.logo_link {

				height: calc(100% - 0.5rem);
				// border: 1px solid red

				z-index: 999;
				
				@include large {
					height: calc(100% - 1rem);
				}

				.logo {

					height: 100%;
					// height: calc(100% - 3rem);
					// border: 1px solid white;

				}
			}

			.nav {

				height: 100%;

				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				padding-top: 80px;
				background: black;

				z-index: 9;

				&.open {
					display: flex;
				}

				&.closed {
					display: none;
				}

				@include medium {
					// top: 100px;
					// height: calc(100% - 100px);
					padding-top: 100px;
				}

				@include large {

					height: auto;
					top: 0;
					background: none;

					z-index: 99;

					display: flex !important;
					justify-content: center;
					align-items: flex-end;
					position: relative;

					&.closed {
						// display: block;
					}

				}

				.list {

					width: 100%;
					height: calc(100% - 2rem);

					display: flex;
					flex-flow: column nowrap;
					justify-content: space-evenly;
					align-items: center;

					// display: flex;
					// flex-flow: row nowrap;
					// justify-content: flex-start;
					// align-items: center;

					padding: 0;
					margin: 1rem;

					// border: 1px solid red;

					@include large {

						flex-flow: row nowrap;
						justify-content: flex-start;
						margin: 0;
						// margin-bottom: 1rem;
						height: auto;

					}

					.item {

						color: white;
						font-size: 1.5rem;
						font-weight: bold;
						text-transform: uppercase;
						list-style: none;
						// margin-left: 2rem;
						cursor: pointer;
						transition: all 0.2s ease;
						opacity: 0.6;

						&:hover {
							opacity: 1;
						}

						@include large {
							margin-left: 2rem;
							height: 100%;
							font-size: 1rem;
							font-weight: 100;
						}

						.link {

							@include large {

								&.closed, &.open {
									display: none;
								}
							}
						}
					}

				}
			}

		}

		.right {

			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: flex-end;

			// border: 1px solid red;

			z-index: 999;

			.contact_button {

				position: relative;
				background-color: white;
				height: 2.5rem;
				border: none;
				border-radius: 1.25rem;
				padding: 0 2rem;
				cursor: pointer;
				transition: all 0.2s ease;

				// display: flex;
				justify-content: center;
				align-items: center;

				display: none;

				@include large {

					display: flex;
					// border: 1px solid green;

				}

				&:hover {

					padding-right: 50px;
					margin-right: calc(2rem - 50px);

					.arrow {

						right: 0.4rem;
						filter: brightness(0) !important;
					}
				}

				@include medium {
					margin-bottom: 1rem;
				}

				.text {

					text-transform: uppercase;
					font-size: 1.1rem;
					color: _palette(black);
					margin: 0;
					z-index: 1;
				}

				.arrow {
					position: absolute;
					top: 15%;
					right: 1rem;
					height: 70%;

					transition: inherit;


					// filter: brightness(0%);
				}
			}

			.menu {

				// border: 1px solid red;
				height: 100%;

				background: none;
				border: none;

				.icon {

					height: 48px;

					cursor: pointer;
					transition: all 0.3s ease-out;

					// border: 1px solid black;

					&:hover {
						// border: 1px solid white;
					}

				}

				@include large {
					display: none;
				}
			}
		}
	}
}