.banner {

	width: 100%;

	.banner_container {

		.title_wrapper {

			width: 100%;

			.title {

				font-size: 3rem;
				font-weight: 100;
				line-height: 0.8em;

				margin: 0;
				margin: 100px 0 0 2rem;
				padding: 0;

				@include small {
					font-size: 3rem;
				}

				@include medium {
					font-size: 6rem;
				}

				@include large {
					font-size: 8rem;
				}

			}

		}

		.bottom {

			width: 100%;
			height: 2rem;

			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;

			.line {

				height: 1px;
				width: 100%;
				background: white;

			}

			.subtitle {

				width: 50%;
				// margin: 0 1rem;
				margin-left: 1rem;
				margin-right: 0.5rem;
				text-transform: uppercase;
				white-space: nowrap;

			}
		}
	}


}