.services {

	.blob {

		position: absolute;
		top: -100px;
		right: -50px;
		height: 400px;
		opacity: 0.7;
		transform: rotate(20deg);

		z-index: -99;

	    @include medium {
			top: -300px;
			left: 300px;
			height: 800px;
	    }

	    @include large { 
			top: -200px;
			left: 300px;
			height: 900px;
	    }

	}

	.main {

		.banner {

			.title_wrapper {

				display: flex;

				.title {

					text-align: right;
					
				}

			}
		}

		.content {

			.paragraph {

				width: 100%;

				// font-size: 1rem;
				// line-height: 2em;
				// letter-spacing: 0.1em;
				margin-bottom: 2rem;
				// font-weight: 100;

				// border: 1px solid gold;

				@include medium {
					width: 90%;
				}

				@include large {
					width: 70%;
				}

			}

			.services_image_slider {
					
				width: 320px;
				height: 180px;
				
				border-radius: 1rem;
				padding: 0;

				margin-bottom: 2rem;
				margin-top: 4rem;

				overflow: hidden !important;

				// border: 1px solid gold;

				@include medium {
					
					width: 640px;
					height: 360px;
					
				}

				@include large {
					
					width: 960px;
					height: 540px;
					
				}

				.image_wrapper {

					// width: 320px;
					// height: 180px;

					display: flex;					

					// border: 1px solid cyan;

					transform: translate(-100%, 0);

					&.animation_left {
						transition: all 1s ease;
						transform: translate(-200%, 0);
					}


					&.animation_right {
						transition: all 1s ease;
						transform: translate(0, 0);
					}

					.image {

						width: 320px;
						height: 180px;
						// display: inline-block;

						@include medium {
							
							width: 640px;
							height: 360px;
							
						}

						@include large {
							
							width: 960px;
							height: 540px;
							
						}

					}

				}
			}

			.buttons {

				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				margin-bottom: 4rem;

				.button {

					background: none;
					width: 40px;
					height: 40px;
					padding: 6px;
					border: 2px solid #C364D9;
					border-radius: 50%;
					margin-right: 1rem;

					cursor: pointer;

					&:first-child {
						transform: rotate(180deg);
					}

					.image {

						width: 100%;
						height: 100%;
					}
				}
			}

			.separator {
				width: 100px;
				height: 1px;
				background: white;
				margin: 4rem 0;
			}

			.services_wrapper {

				width: 100%;

				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 4rem;

				@include large {

					flex-flow: row nowrap;

				}

				.lists, .contact {

					width: 100%;

					@include medium {
						width: 90%;
					}

					@include large {
						width: 50%;
					}

				}

				.lists {

					// border: 1px solid cyan;

					display: flex;
					flex-flow: column nowrap;
					justify-content: space-evenly;
					align-items: flex-start;

					margin-bottom: 4rem;

					@include medium {
						flex-flow: row nowrap;
					}

					@include large {
						margin-bottom: 0;
					}

					.list {

						// border: 1px solid salmon;
						list-style-type: "+ ";

						width: 100%;
						// padding: 0;
						margin: 0;

						.item {

							font-size: 1rem;
							line-height: 1.2em;
							opacity: 0.9;

							@include medium {

								// font-size: 1.1rem;
								line-height: 2em;

							}

							@include large {

								// font-size: 1.2rem;
								line-height: 1.5em;
								letter-spacing: 0.1em;

							}

						}
					}

				}

				.contact {

					width: 40%;

  					$border: 5px;

  					position: relative;
  					box-sizing: border-box;

					padding: 1rem 2rem;
					padding-right: 4rem;
					margin-right: calc(-4rem - 6px);

					color: #FFF;
					background-color: black;
					background-image: url("../../images/noise_web.png");
					background-clip: padding-box;
					border: solid $border transparent;
					border-radius: 2em 0 0 2em;

					align-self: flex-end;

					@include medium {
						// padding: 2rem 4rem;
					}

  					&:before {
					    content: '';
					    position: absolute;
					    top: 0; right: 0; bottom: 0; left: 0;
					    z-index: -1;
					    margin: -$border;
					    border-radius: inherit;
					    background: linear-gradient(140deg, #FF27F8, #FF6F00);
					  }

					.write {

						font-size: 1rem;
						// line-height: 1.2em;
						letter-spacing: 0.1em;
						font-weight: bold;
						text-align: right;

						@include medium {

							// font-size: 1.4rem;
							// letter-spacing: 0.1em;

						}

					}

					.email {

						font-size: 1.2rem;
						letter-spacing: 0.1em;
						text-align: right;
						font-weight: 100;

						@include medium {

							font-size: 1.5rem;

						}

					}

				}
			}
		}
	}
}


