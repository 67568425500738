
@mixin small {
   @media (min-width: _size(breakpoint, small)) {
       @content;
   }
}

@mixin medium {
   @media (min-width: _size(breakpoint, medium)) {
       @content;
   }
}

@mixin large {
   @media (min-width: _size(breakpoint, large)) {
       @content;
   }
}

@mixin xlarge {
   @media (min-width: _size(breakpoint, xlarge)) {
       @content;
   }
}