* {
	box-sizing: border-box;
}

html {
	width: 100%;

	// border: 1px solid gold;
	// overflow-y: scroll;
	// overflow-x: hidden !important;
	position: relative;
}

html,
body {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.body {

	width: 100vw;

	background-color: black;
	background-image: url("../../images/noise_web.png");
	// overflow: hidden;

	// border: 1px solid gold;
	// position: fixed;
	// top: 0;
	// left: 0;
	// right: 0;
	// width: 100%;
	overflow-x: hidden !important;
	overflow-y: scroll !important;

	// touch-action: none;
	// -ms-touch-action: none;

	.root {

		width: 100%;
		max-width: 1920px;
		margin: 0 auto;

		// overflow-x: hidden;

		.container {

			position: relative;

			min-height: 100vh;
			width: calc(100% - 2rem);
			margin: 0 auto;

			// overflow: hidden;

			@include medium {
				width: calc(100% - 4rem);
			}

			@include large {
				width: calc(100% - 8rem);
			}

			@include xlarge {
				width: 100%;
			}

			.main {

				.content {

					width: 100%;
					min-height: 600px;
					margin: 2rem 0;

					margin-bottom: 200px !important;

					.holder {
						font-size: 1.2rem;
					}
				}
			}

		}
	}
}