.maker {

	.main {

		.title {

			font-family: _font(family_body);
			font-weight: bold;
			font-size: 3rem;
			letter-spacing: 0.2rem;
			text-align: center;
			text-transform: uppercase;

			margin-top: 8rem;
			margin-bottom: 4rem;

			// border: 1px solid cyan;

			@include medium {
				font-size: 4rem;
			}

			@include large {
				font-size: 4rem;
			}

		}

		.description {

			// border: 1px solid grey;
			margin-bottom: 4rem;

			.text {
				font-size: 1rem;
				text-align: center;
			}
		}

		.maker_wrapper {

			// border: 1px solid gold;

			width: 100%;

			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;

			margin-bottom: 4rem;

			.maker_container {

				width: 100%;
				// height: 400px;

				// display: flex;
				// margin: 2rem auto;
				// border-radius: 4rem;
				overflow: hidden;

				// border: 1px solid cyan;

	  			&:hover {
	          		cursor: pointer;
	  			}

	  			@include medium {
					width: 50%;
	  			}

	  			@include large {
					width: 33.3333%;
	  			}

				.maker_image {

					width: 100%;
					transition: all 0.2s ease;
					display: flex;

					&:hover {
						cursor: pointer;
						transform: scale(1.05, 1.05);
					}
				}
			}
		}
	}
}