.footer {

	// height: 48px;
	width: 100%;
	margin: 0 auto;
  	background: none;

  	border-top: 1px solid white;

  	display: flex;
  	flex-flow: column nowrap;
  	justify-content: space-evenly;
  	align-items: flex-start;
  	padding: 1rem;
  	text-align: left;

  	// border: 1px solid red;

  	@include medium {
  		flex-flow: row nowrap;
  		padding: 1rem;
  	}

  	.section {

  		width: 100%;
  		display: flex;
  		flex-flow: row nowrap;
	  	justify-content: flex-start;
	  	align-items: center;
	  	margin-bottom: 1rem;

	  	// border: 1px solid red;

	  	@include medium {
	  		justify-content: center;
	  	}
	  	@include large {
	  		margin-bottom: 2rem;
	  	}

	  	.content {

		  	.title {

		  		color: white;
		  		text-transform: uppercase;
		  		font-size: 1.2rem;
		  		font-weight: bold;
		  		margin: 2rem 0 !important;
		  		line-height: 1.2em;
		  		text-align: left;

			  	@include medium {
			  		font-size: 1.4rem;
			  	}

			  	&.conversation {

			  		cursor: pointer;
			  		border-bottom: 2px solid #0000;
			  		line-height: 1em;
			  		// margin-bottom: 2rem !important;

					border-image-slice: 1;
					border-width: 2px;
					border-image: linear-gradient(90deg, #FF762E, #FF8B93) 1;

			  		transition: all 0s ease;

			  		&:hover {

				  		background: linear-gradient(90deg, #FF762E, #FF8B93);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						// letter-spacing: 2px;
						
						// border-bottom: 1px solid #0000;
				  		// border-image: linear-gradient(90deg, red, yellow) 1;

			  			transition: all 0.2s ease;

			  		}
			  	}
		  	}

		  	.address {

		  		color: white;
		  		text-transform: uppercase;
		  		font-size: 1rem;
		  		line-height: 1.1em;
		  		margin: 0.2rem 0;
		  		text-align: left;

		  	}

		  	.email {

		  		width: auto;

		  		font-size: 1rem;
		  		// font-weight: bold;
		  		text-transform: uppercase;
				border-bottom: 2px solid #0000;

		  		line-height: 1.5em;

		  		cursor: pointer;

				border-image-slice: 1;
				border-width: 2px;
				border-image: linear-gradient(90deg, #FF762E, #FF8B93) 1;

		  		transition: all 0s ease;

		  		&:hover {

			  		background: linear-gradient(90deg, #FF762E, #FF8B93);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					
					// border-bottom: 1px solid #0000;
			  		// border-image: linear-gradient(90deg, red, yellow) 1;

		  			transition: all 0.2s ease;

		  		}

		  		@include small {
		  			font-size: 1.25rem;
		  		}

		  	}

		  	.social {

		  		padding: 0;

		  		display: flex;
		  		flex-flow: row nowrap;
		  		justify-content: flex-start;
		  		align-items: flex-start;
		  		margin-top: 2rem;

		  		.item {

		  			list-style: none;
				  	margin: 0;
				  	margin-right: 1rem;

		  			.link {

		  				color: white;

		  				.logo {
		  					width: 36px;
		  					height: 36px;
		  				}

		  				.text {
					  		// color: white;
					  		text-transform: uppercase;
					  		font-size: 1rem;
					  		margin: 0;
		  					transition: all 0s ease;

			  				&:hover {
		  						
		  						transition: all 0.3s ease;

			  					&.facebook {
						  			background: linear-gradient(90deg, #D756E5, #E8E8E8);
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
						  		}
			  					
			  					&.instagram {
						  			background: linear-gradient(90deg, #E3306B, #E8E8E8);
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
						  		}
			  					
			  					&.tiktok {
						  			background: linear-gradient(90deg, #FF762E, #E8E8E8);
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
						  		}

			  				}

		  				}
		  			}
		  		}
		  	}

		}

  	}

}